module.exports = function (carousel) {
    extend(this);

    this.carousel = carousel;
    this.lastKeyDownTime = new Date();
    this.lastProcessedEvent = null;
    this.nonInterruptible = false;
    this.destroyables = [];

    this.carousel.widget().keyup(this.destroyable("keyup", $.proxy(this.onKeyUp, this)));
    this.carousel.widget().keydown(this.destroyable("keydown", $.proxy(this.onKeyDown, this)));

    this.carousel.widget().mousedown(this.destroyable("mousedown", $.proxy(function (e) {
        this.carousel.widget().focus();
        this.motionStarted(e.pageX);
        e.preventDefault();
    }, this)));
    this.carousel.widget().mousemove(this.destroyable("mousemove", $.proxy(function (e) {
        if (this.canProcessEvent()) {
            this.registerEventAsProcessed();
            this.motionContinued(e.pageX);
        }
        e.preventDefault();
    }, this)));
    this.carousel.widget().mouseleave(this.destroyable("mouseleave", $.proxy(function (e) {
        if (this.nonInterruptible)
            return;
        this.carousel.motionController.motionEnded(true);
        e.preventDefault();
    }, this)));
    this.carousel.widget().mouseup(this.destroyable("mouseup", $.proxy(function (e) {
        this.carousel.motionController.motionEnded(true);
        e.preventDefault();
    }, this)));

    this.carousel.widget().on("mousewheel", this.destroyable("mousewheel", $.proxy(this.onMousewheel, this)));
    this.carousel.widget().on("DOMMouseScroll", this.destroyable("DOMMouseScroll", $.proxy(this.onMousewheel, this)));
    
    this.carousel.widget().on('touchstart', this.destroyable("touchstart", $.proxy(function (e) {
        this.motionStarted(e.originalEvent.touches[0].screenX);
    }, this)));
    this.carousel.widget().on('touchmove', this.destroyable("touchmove", $.proxy(function (e) {
        if (this.canProcessEvent()) {
            this.registerEventAsProcessed();
            this.motionContinued(e.originalEvent.touches[0].screenX);
        }
        e.preventDefault();
    }, this)));
    this.carousel.widget().on('touchend', this.destroyable("touchend", $.proxy(function (e) {
        this.carousel.motionController.motionEnded(true);
    }, this)));
    this.carousel.widget().on('touchcancel', this.destroyable("touchcancel", $.proxy(function (e) {
        this.carousel.motionController.motionEnded(true);
    }, this)));
    this.carousel.widget().on('taphold', this.destroyable("taphold", function (e) { e.preventDefault(); }));
};

function extend(obj) {
    obj.nonInterruptibleMode = function (nonInterruptible) {
        this.nonInterruptible = nonInterruptible;
    };

    obj.canProcessEvent = function () {
        if (this.lastProcessedEvent == null)
            return true;

        if (new Date() - this.lastProcessedEvent > 50)
            return true;

        return false;
    };

    obj.registerEventAsProcessed = function () {
        this.lastProcessedEvent = new Date();
    };

    obj.motionStarted = function (x) {
        this.carousel.motionController.motionStarted(x);
        this.initialX = x;
    };

    obj.motionContinued = function (x) {
        var delta = this.initialX - x;
        delta *= this.carousel.options.sensitivity;
        delta /= this.carousel.fluidLayout.getApplayedScale();
        x = this.initialX - delta;

        this.carousel.motionController.motionContinued(x);
    };

    obj.onMousewheel = function (event) {
        if (this.carousel.getIsInMotion())
            return;

        var up = false;
        var down = false;
        var original = event.originalEvent;
        if (original.wheelDelta) {
            if (original.wheelDelta >= 120) {
                up = true;
            }
            else {
                if (original.wheelDelta <= -120) {
                    down = true;
                }
            }
        }

        if (original.detail) {
            if (original.detail == -3)
                up = true;
            else
                if (original.detail == 3)
                    down = true;
        }
        
        if (up) {
            if (this.carousel.options.sensitivity > 0)
                this.carousel.moveBack();
            if (this.carousel.options.sensitivity < 0)
                this.carousel.moveForward();
        }
        if (down) {
            if (this.carousel.options.sensitivity < 0)
                this.carousel.moveBack();
            if (this.carousel.options.sensitivity > 0)
                this.carousel.moveForward();
        }
        
        event.preventDefault();
    };

    obj.onKeyDown = function (event) {
        if (this.carousel.motionController.isInMotion || this.carousel.motionController.inertia.isInProgress)
            return;

        if ((new Date() - this.lastKeyDownTime) < this.carousel.options.minKeyDownFrequency)
            return;

        this.lastKeyDownTime = new Date();

        if (event.which == 39 /*right*/) {
            if (this.carousel.options.sensitivity > 0)
                this.carousel.moveBack();
            if (this.carousel.options.sensitivity < 0)
                this.carousel.moveForward();
        }
        if (event.which == 37 /*left*/) {
            if (this.carousel.options.sensitivity < 0)
                this.carousel.moveBack();
            if (this.carousel.options.sensitivity > 0)
                this.carousel.moveForward();
        }
    };

    obj.onKeyUp = function (event) {
        if (this.carousel.motionController.isInMotion || this.carousel.motionController.inertia.isInProgress)
            return;

        if (event.which == 39 /*right*/ || event.which == 37 /*left*/) {
            this.carousel.animation.clearQueue();
        }
    };

    obj.destroyable = function (key, func) {
        this.destroyables[key] = func;
        return func;
    };

    obj.destroy = function () {
        for (var key in this.destroyables) {
            this.carousel.widget().off(key, this.destroyables[key]);
        }
    };
}
module.exports = function() {
    this.translateX = 0;
    this.translateY = 0;
    this.translateZ = 0;
    this.scale = 1;

    this.scaleX = 1;
    this.scaleY = 1;
    this.scaleZ = 1;

    this.rotations = [];

    this.apply = function (e) {
        var str = '';
        if (this.translateX != 0 || this.translateY != 0 || this.translateZ != 0)
            str = 'translate3d(' + this.translateX + 'px, ' + this.translateY + 'px, ' + this.translateZ + 'px)';

        if (this.scale != 1)
            str += ' scale(' + this.scale + ')';

        if (this.scaleX != 1)
            str += ' scaleX(' + this.scaleX + ')';
        if (this.scaleY != 1)
            str += ' scaleY(' + this.scaleY + ')';
        if (this.scaleZ != 1)
            str += ' scaleZ(' + this.scaleZ + ')';

        for (var i = 0; i < this.rotations.length; i++)
            str += ' ' + this.rotations[i].getString();

        e.style.transform = str;
        e.style.webkitTransform = str;
        e.style.msTransform = str;
    };
};
module.exports = function (carousel, settings) {

    var path_base = require('./path_base.js');
    var point = require('./../point.js');

    var base = new path_base(carousel, {
        a: 200,
        b: 200
    });

    $.extend(this, base);
    $.extend(this.settings, settings);

    this.getPointInternal = function (value) {
        var angle = value * Math.PI * 2 / 360;
        var z = this.settings.b * Math.sin(angle);
        var x = this.settings.a * Math.cos(angle);

        return new point(x, 0, z);
    };

    this.rootValue = function () {
        return 90;
    };

    this.minValue = function () {
        return 0;
    };

    this.maxValue = function () {
        return 180;
    };
};
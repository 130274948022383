var utils = function() {
    this.getObjectPropertyValue = function (obj, property) {
        var parts = property.split('.');
        var res = obj;
        for (var i = 0; i < parts.length; i++) {
            res = res[parts[i]];
        }

        return res;
    };

    this.setObjectPropertyValue = function (obj, property, value) {
        var parts = property.split('.');
        var target = obj;
        for (var i = 0; i < parts.length - 1; i++) {
            target = target[parts[i]];
        }

        target[parts[parts.length - 1]] = value;
    }
}

module.exports = new utils();
module.exports = function (carousel, settings) {

    var path_base = require('./path_base.js');
    var point = require('./../point.js');

    var base = new path_base(carousel, {
        
    });

    $.extend(this, base);
    $.extend(this.settings, settings);

    this.getPointInternal = function (value) {
        var z = -1 * value;
        return new point(0, 0, z);
    };
};
module.exports = function (carousel, settings) {

    var effect_base = require('./effect_base.js');

    var base = new effect_base(carousel, {

    });

    $.extend(this, base);
    $.extend(this.settings, settings);

    this.apply = function (elementTransform, element, value) {

        var boxShadow = '0px 0px ' + this.carousel.options.shadowBlurRadius + 'px ' + this.carousel.options.shadowSpreadRadius + 'px #000000';

        element.$element.css({
            '-webkit-box-shadow': boxShadow,
            '-moz-box-shadow': boxShadow,
            'box-shadow': boxShadow
        });
    };

    this.revert = function () {
        for (var i = 0; i < this.carousel.elements.length; i++) {
            this.carousel.elements[i].$element.css({
                '-webkit-box-shadow': '',
                '-moz-box-shadow': '',
                'box-shadow': ''
            });
        }
    };
};
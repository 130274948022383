require('./easings.js')(); // patch jquery with easings

/*!
 * TODO: add about
 * 
 */

(function ($) {
    if (!$.theta) {
        $.theta = new Object();
    };

    var comulative_animation = require('./comulative_animation.js');
    var elements_visibility = require('./elements_visibility.js');
    var fluid_layout = require('./fluid_layout.js');
    var elements_size_updater = require('./elements_size_updater.js');
    var input_controller = require('./input_controller.js');
    var motion_controller = require('./motion_controller.js');
    var size = require('./size.js');
    var transform = require('./transform.js');
    var utils = require('./utils.js');

    var path_archimedes_spiral = require('./paths/path_archimedes_spiral.js');
    var path_cubic = require('./paths/path_cubic.js');
    var path_cubic_bezier = require('./paths/path_cubic_bezier.js');
    var path_ellipse = require('./paths/path_ellipse.js');
    var path_line = require('./paths/path_line.js');
    var path_parabola = require('./paths/path_parabola.js');
    
    var effect_allign_to_path = require('./VisualEffects/effect_allign_to_path.js');
    var effect_fade_away = require('./VisualEffects/effect_fade_away.js');
    var effect_pop_out_selected = require('./VisualEffects/effect_pop_out_selected.js');
    var effect_rotation = require('./VisualEffects/effect_rotation.js');
    var effect_shadow = require('./VisualEffects/effect_shadow.js');
    var effect_size_adjustment = require('./VisualEffects/effect_size_adjustment.js');

    var version = '1.0.0';
    var defaultOptions = {
        filter: "div",
        selectedIndex: 0,
        distance: 70,
        mode3D: 'z',
        scaleX: 1,
        scaleY: 1,
        scaleZ: 1,
        perspective: 1000,
        numberOfElementsToDisplayRight: null,
        numberOfElementsToDisplayLeft: null,
        sensitivity: 1,
        minKeyDownFrequency: 0,
        rotationAnimationEasing: 'easeOutCubic',
        rotationAnimationDuration: 500,
        inertiaFriction: 10,
        inertiaHighFriction: 50,
        path: {
            type: "parabola",
            settings: {}
        },
        designedForWidth: null,
        designedForHeight: null,

        // effects
        allignElementsWithPath: false,
        allignElementsWithPathCoeficient: 1,

        fadeAway: false,
        fadeAwayNumberOfConfigurableElements: 5,
        fadeAwayBezierPoints: { p1: { x: 0, y: 100 }, p2: { x: 50, y: 50 }, p3: { x: 50, y: 50 }, p4: { x: 100, y: 0 } },

        rotation: false,
        rotationVectorX: 0,
        rotationVectorY: 0,
        rotationVectorZ: 0,
        rotationNumberOfConfigurableElements: 5,
        rotationBezierPoints: { p1: { x: 0, y: 0 }, p2: { x: 50, y: 0 }, p3: { x: 50, y: 0 }, p4: { x: 100, y: 0 } },
        rotationInvertForNegative: false,

        sizeAdjustment: false,
        sizeAdjustmentNumberOfConfigurableElements: 5,
        sizeAdjustmentBezierPoints: { p1: { x: 0, y: 100 }, p2: { x: 50, y: 100 }, p3: { x: 50, y: 100 }, p4: { x: 100, y: 100 } },

        shadow: false,
        shadowBlurRadius: 100,
        shadowSpreadRadius: 0,

        popoutSelected: false,
        popoutSelectedShiftX: 0,
        popoutSelectedShiftY: 0,
        popoutSelectedShiftZ: 0

    };

    $.theta.carousel = function (domElement, options) {

        var carousel = this;
        carousel.$element = $(domElement);
        carousel.$element.data("theta.carousel", carousel);
        carousel.$element.addClass('theta-carousel');

        carousel._create = function () {
            this.options = $.extend(true, {}, $.theta.carousel.defaultOptions, options);

            // prepare container
            var containerSize = new size(this.widget().width(), this.widget().height());

            this.container = $('<div class="theta-carousel-inner-container"></div>');
            this.container.appendTo(this.widget());
            this.container.css({
                width: containerSize.width + 'px',
                height: containerSize.height + 'px'
            });
            this.widget().children(':not(.theta-carousel-inner-container)').appendTo(this.container);

            this.widget().attr('tabindex', 0).css({ outline: 'none', overflow: 'hidden' });
            this.container.css({
                transformStyle: 'preserve-3d',
                overflow: 'hidden',
                perspective: this.options.perspective + 'px',
                transform: 'translate3d(0px,0px, 100000px)'
            });

            // prepare elements
            
            this.elements = this.container.children().filter(this.options.filter).map(function (i, e) {
                var $e = $(e);
                return { $element: $e, element: e, index: i };
            }).toArray();

            for (var i = 0; i < this.elements.length; i++) {
                this.elements[i].$element.css({ position: 'absolute' });
                this.elements[i].$element.on('tap', $.proxy(this.moveTo, this, i));
                this.elements[i].$element.click($.proxy(this.moveTo, this, i));
            }

            // prepare widget
            this.effects = [];
            this._createPath();
            this._createEffects();
            this.eVisibility = new elements_visibility(this);
            this.elementsSizeUpdater = new elements_size_updater(this);
            this.fluidLayout = new fluid_layout(this);
            this._alignElements();
            this.animation = new comulative_animation(this);
            this.motionController = new motion_controller(this, $.proxy(this._motionConsumer, this));
            this.inputController = new input_controller(this);

            // attach event listeners
            $(this.animation).on('step', $.proxy(function (e, shift) { this._alignElements(shift); }, this));
            $(this.animation).on('done', $.proxy(function (e, value) {
                this._rotationAnimationCompleted(value);
                this._raiseMotionEnd();
            }, this));
            $(this.motionController).on('end', $.proxy(function (e, value) { this._motionEnd(value); }, this));
            $(this.motionController).on('start', $.proxy(this._raiseMotionStart, this));
        };

        carousel.destroy = function () {
            this.inputController.destroy();
            this.fluidLayout.destroy();
            this.elementsSizeUpdater.destroy();
            for (var i = 0; i < this.elements.length; i++) {
                this.elements[i].$element.off('tap', this.moveTo);
                this.elements[i].$element.off("click", this.moveTo);
            }
            this.widget().data('theta.carousel', null);
        };

        carousel._setOption = function (name, value) {
            
            utils.setObjectPropertyValue(carousel.options, name, value);

            if (name === 'perspective') {
                this.container.css({ perspective: value + 'px' });
                if (this.options.mode3D == 'scale')
                    this._alignElements();
            }

            if (name.indexOf('path') == 0) {
                this._createPath();
                this._alignElements();
            }

            if (name === "selectedIndex" || name === "distance" || name === "mode3D"
                || name === "numberOfElementsToDisplayRight" || name === "numberOfElementsToDisplayLeft"
                || name === "scaleX" || name === "scaleY" || name === "scaleZ"
                || name === "allignElementsWithPathCoeficient"
                || name === "fadeAwayBezierPoints" || name === "fadeAwayNumberOfConfigurableElements"
                || name === "rotationBezierPoints" || name === "rotationNumberOfConfigurableElements" || name === "rotationInvertForNegative"
                || name === "rotationVectorX" || name === "rotationVectorY" || name === "rotationVectorZ"
                || name === "sizeAdjustmentNumberOfConfigurableElements" || name === "sizeAdjustmentBezierPoints"
                || name === "shadowBlurRadius" || name === "shadowSpreadRadius"
                || name === "popoutSelectedShiftX" || name === "popoutSelectedShiftY" || name === "popoutSelectedShiftZ"
                ) {
                this._alignElements();
            }

            if (name.indexOf('allignElementsWithPath') != -1 || name.indexOf('fadeAway') != -1 || name.indexOf('rotation') != -1
                || name.indexOf('sizeAdjustment') != -1 || name.indexOf('shadow') != -1 || name.indexOf('popoutSelected') != -1) {
                this._createEffects();
                this._alignElements();
            }
        };

        carousel.widget = function () {
            return this.$element;
        };

        carousel.moveTo = function (index) {

            if (this.motionController.motionInProgress())
                return;

            this.animation.clearQueue();
            this.animation.comliteCurrentImmediately();

            if (index == this.options.selectedIndex)
                return;
            if (index == this.options.selectedIndex + 1) {
                this.moveForward();
                return;
            }
            if (index == this.options.selectedIndex - 1) {
                this.moveBack();
                return;
            }

            index = Math.max(0, index);
            index = Math.min(index, this.elements.length - 1);

            var distance = this.options.distance * (this.options.selectedIndex - index);
            this.inputController.nonInterruptibleMode(true);
            this._raiseMotionStart();
            this.animation.animate(0, distance, index, "linear");
        };

        carousel.moveBack = function () {
            var pendingTarget = this.animation.isInProgress ? this.animation.getTargetValue() : this.options.selectedIndex;

            if (pendingTarget > 0) {
                pendingTarget--;

                this.inputController.nonInterruptibleMode(true);
                this._raiseMotionStart();
                this.animation.animate(0, this.options.distance, pendingTarget, null);
            }
        };

        carousel.moveForward = function () {
            var pendingTarget = this.animation.isInProgress ? this.animation.getTargetValue() : this.options.selectedIndex;

            if (pendingTarget < this.elements.length - 1) {
                pendingTarget++;

                this.inputController.nonInterruptibleMode(true);
                this._raiseMotionStart();
                this.animation.animate(0, -1 * this.options.distance, pendingTarget, null);
            }
        };

        carousel.invalidate = function () {
            if(!this._isInMotion)
                this._alignElements();
        };

        carousel.getIsInMotion = function () {
            return this._isInMotion;
        };

        carousel._raiseChangeEvent = function () {
            this.widget().trigger("change", { index: this.options.selectedIndex });
        };

        carousel._raiseMotionStart = function () {
            this._isInMotion = true;
            this.widget().trigger("motionStart", { index: this.options.selectedIndex });
        };

        carousel._raiseMotionEnd = function () {
            this.inputController.nonInterruptibleMode(false);
            this._isInMotion = false;
            this.widget().trigger("motionEnd", { index: this.options.selectedIndex });
        };

        carousel._createEffects = function () {

            for (var i = 0; i < this.effects.length; i++) {
                this.effects[i].revert();
            }

            this.effects = [];

            if (this.options.allignElementsWithPath)
                this.effects.push(new effect_allign_to_path(this, {}));

            if (this.options.fadeAway)
                this.effects.push(new effect_fade_away(this, {}));

            if (this.options.rotation)
                this.effects.push(new effect_rotation(this, {}));

            if (this.options.sizeAdjustment)
                this.effects.push(new effect_size_adjustment(this, {}));

            if (this.options.shadow)
                this.effects.push(new effect_shadow(this, {}));

            if (this.options.popoutSelected)
                this.effects.push(new effect_pop_out_selected(this, {}));
        };

        carousel._createPath = function () {
            var newPath = null;

            if (this.options.path.type == "parabola") {
                newPath = new path_parabola(this, this.options.path.settings);
            }

            if (this.options.path.type == "line") {
                newPath = new path_line(this, this.options.path.settings);
            }

            if (this.options.path.type == "cubic") {
                newPath = new path_cubic(this, this.options.path.settings);
            }

            if (this.options.path.type == "archimedes_spiral") {
                newPath = new path_archimedes_spiral(this, this.options.path.settings);
            }

            if (this.options.path.type == "ellipse") {
                newPath = new path_ellipse(this, this.options.path.settings);
            }

            if (this.options.path.type == "cubic_bezier") {
                newPath = new path_cubic_bezier(this, this.options.path.settings);
            }

            if (newPath != null) {
                this.path = newPath;
                this.options.path.settings = this.path.settings;
            } else
                throw "path " + this.options.path.type + " is not supported.";
        };

        carousel._rotationAnimationCompleted = function (index) {
            if (this.options.selectedIndex != index) {
                this.options.selectedIndex = index;
                this._raiseChangeEvent();
            }
            this._alignElements();
        };

        carousel._motionConsumer = function (distance) {
            var highFrictionRange = this._alignElements(distance);

            var scrolledElements = parseInt(distance / this.options.distance, 10);

            var prevIndex = this.options.selectedIndex;
            this.options.selectedIndex -= scrolledElements;

            this.options.selectedIndex = Math.max(0, this.options.selectedIndex);
            this.options.selectedIndex = Math.min(this.options.selectedIndex, this.elements.length - 1);

            if (prevIndex != this.options.selectedIndex)
                this._raiseChangeEvent();

            return { distance: (prevIndex - this.options.selectedIndex) * this.options.distance, highFrictionRange: highFrictionRange };
        };

        carousel._motionEnd = function (remainingDistance) {
            var targetIndex = this.options.selectedIndex;

            if (Math.abs(remainingDistance) > this.options.distance / 2) {
                if (remainingDistance < 0)
                    targetIndex++;
                else
                    targetIndex--;
            }

            if (this.elements.length == 0)
                targetIndex = 0;
            else {
                targetIndex = Math.max(0, targetIndex);
                targetIndex = Math.min(targetIndex, this.elements.length - 1);
            }

            var targetDistance = (this.options.selectedIndex - targetIndex) * this.options.distance;

            var duration = Math.abs(this.options.rotationAnimationDuration * (remainingDistance / this.options.distance));
            duration = Math.min(duration, this.options.rotationAnimationDuration / 2);

            this.animation.animate(remainingDistance, targetDistance, targetIndex, null, duration);
        };

        carousel._alignElements = function (animationShift) {
            this.containerSize = this._getContainerSize();

            var shift = 0;
            if (typeof (animationShift) != "undefined")
                shift = animationShift;

            var highFrictionRange = false;
            // slow down at the ends
            if (
                (this.options.selectedIndex == 0 && shift > 0) ||
                (this.options.selectedIndex == this.elements.length - 1 && shift < 0)
            ) {
                shift = Math.pow(Math.abs(shift), 0.7) * (shift / Math.abs(shift));
                highFrictionRange = true;
            }

            var location = this.path.rootValue();
            var rangeShift = 0;
            if ((this.options.selectedIndex == 0 && shift > 0) || (this.options.selectedIndex == this.elements.length - 1 && shift < 0))
                rangeShift = shift;
            var ranges = this.eVisibility.getFadeRanges(location + rangeShift);

            for (var i = this.options.selectedIndex; i < this.elements.length; i++) {
                this._setElementPosition(this.elements[i], location + shift, ranges);
                location = this.path.incrementValue(location, this.options.distance);
            }

            location = this.path.rootValue();

            for (var i = this.options.selectedIndex - 1; i >= 0; i--) {
                location = this.path.decrementValue(location, this.options.distance);
                this._setElementPosition(this.elements[i], location + shift, ranges);
            }

            this._setZIndexes();

            return highFrictionRange;
        };

        carousel._setElementPosition = function (element, value, ranges) {
            //this method is performance critical so we trying to avoid jQuery usage
            
            var point = this.path.getPoint(value);

            if (this.eVisibility.setElementVisibility(ranges, element.$element, value)) {

                var elementTransform = new transform();

                elementTransform.translateZ = point.z * this.options.scaleZ;
                elementTransform.translateX = point.x * this.options.scaleX + this.containerSize.width / 2 - element.size.width / 2;
                elementTransform.translateY = point.y * this.options.scaleY + this.containerSize.height / 2 - element.size.height / 2;

                if (this.options.mode3D == 'scale') {
                    elementTransform.scale = this.options.perspective / (this.options.perspective - elementTransform.translateZ);
                    elementTransform.translateZ = 0;
                }

                for (var i = 0; i < this.effects.length; i++) {
                    this.effects[i].apply(elementTransform, element, value);
                }

                elementTransform.apply(element.element);
            }

            element.location = point;
        };

        carousel._setZIndexes = function () {

            var elements = this.elements.slice(0, this.elements.length);

            elements.sort(function (e1, e2) {
                return e1.location.z - e2.location.z;
            });
            for (var i = 0; i < elements.length; i++) {
                elements[i].$element.get(0).style.zIndex = i;
            }
        };

        carousel._getContainerSize = function () {
            var container = $('.theta-carousel-inner-container', this.widget());
            return new size(container.width(), container.height());
        };

        carousel._create();
    };

    $.theta.carousel.defaultOptions = defaultOptions;
    $.theta.carousel.version = version;

    $.fn.theta_carousel = function (options) {
        var callArguments = arguments;

        var hasCallRes = false;
        var callRes = null;

        var eachRes = this.each(function () {
            var $el = $(this);
            var instance = $el.data('theta.carousel');

            if (instance) {
                if (typeof options === 'string') {

                    if (typeof instance[options] === 'function') {
                        var args = Array.prototype.slice.call(callArguments, 1);
                        hasCallRes = true;
                        callRes = instance[options].apply(instance, args);
                    }

                    if (options == 'option') {
                        if (callArguments.length == 2) {
                            hasCallRes = true;
                            callRes = utils.getObjectPropertyValue(instance.options, callArguments[1]);
                        }

                        if (callArguments.length == 3) {
                            instance._setOption(callArguments[1], callArguments[2]);
                        }
                    }
                    
                } else {
                    var clone = $.extend(true, {}, options);
                    $.each(clone, $.proxy($el.data('theta.carousel')._setOption, $el.data('theta.carousel')));
                }
            }
            else 
                (new $.theta.carousel(this, options));
        });

        if (!hasCallRes)
            return eachRes;
        else
            return callRes;
    };

})(jQuery);
module.exports = function(carousel) {
    this.carousel = carousel;

    this.getFadeRanges = function (root) {
        var location = root;

        var res = [];

        if (this.carousel.options.numberOfElementsToDisplayLeft != null) {
            res.push({
                from: this.carousel.path.decrementValue(location, this.carousel.options.distance * (this.carousel.options.numberOfElementsToDisplayLeft + 1)),
                to: this.carousel.path.decrementValue(location, this.carousel.options.distance * (this.carousel.options.numberOfElementsToDisplayLeft)),
                hide: 'before'
            });
        }

        if (this.carousel.options.numberOfElementsToDisplayRight != null) {
            res.push({
                from: this.carousel.path.incrementValue(location, this.carousel.options.distance * (this.carousel.options.numberOfElementsToDisplayRight)),
                to: this.carousel.path.incrementValue(location, this.carousel.options.distance * (this.carousel.options.numberOfElementsToDisplayRight + 1)),
                hide: 'after'
            });
        }

        return res;
    };

    this.setElementVisibility = function (fadeRanges, $element, value) {
        var hidden = false;

        if ((this.carousel.path.minValue() != null && value < this.carousel.path.minValue())
            || (this.carousel.path.maxValue() != null && value > this.carousel.path.maxValue()))
            hidden = true;
        else {

            if (fadeRanges.length == 0)
                $element.css({ opacity: 1 });

            for (var i = 0; i < fadeRanges.length; i++) {
                var range = fadeRanges[i];

                if ((range.hide == 'before' && value <= range.from) || (range.hide == 'after' && value >= range.to)) {
                    hidden = true;
                    break;
                }

                if (value > range.from && value < range.to) {
                    var distance = range.to - range.from;
                    var passed = Math.abs(value - (range.hide == 'after' ? range.from : range.to));
                    var opacity = (distance - passed) / distance;
                    $element.css({ opacity: opacity });
                    break;
                } else {
                    $element.css({ opacity: 1 });
                }
            }
        }

        if (hidden) {
            $element.hide();
            return false;
        } else {
            $element.show();
            return true;
        }
    };
};
module.exports = function (carousel) {

    extend(this);

    this.carousel = carousel;
    this.interval = setInterval($.proxy(this.update, this), 500);

    this.update();
};

function extend(obj) {
    
    obj.update = function (element) {
        var invalidate = false;
        for (var i = 0; i < this.carousel.elements.length; i++) {
            var e = this.carousel.elements[i];
            
            var oldSize = null;
            if (e.size)
                oldSize = e.size;

            e.size = {
                height: e.$element.height(),
                width: e.$element.width()
            }

            if (!oldSize || oldSize.height != e.size.height || oldSize.width != e.size.width)
                invalidate = true;
        }

        if (invalidate)
            this.carousel.invalidate();
    };

    obj.destroy = function () {
        clearInterval(this.interval);
    };
}
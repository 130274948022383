module.exports = function (carousel, motionConsumer) {
    var inertia = require('./inertia.js');
    
    extend(this);

    this.isInMotion = false;
    this.lastPosition = null;
    this.distance = 0;
    this.motionConsumer = motionConsumer;
    this.inertia = new inertia(carousel);
    $(this.inertia).on('complete', $.proxy(this.motionEnded, this, false));
    $(this.inertia).on('stop', $.proxy(this.inertiaStop, this));
    $(this.inertia).on('step', $.proxy(function (e, value) { this.motionContinuedInternal(value); }, this));
};

function extend(obj) {
    obj.motionInProgress = function () {
        return this.inertia.isInProgress || this.isInMotion;
    };

    obj.motionStarted = function (position) {

        if (!this.inertia.isInProgress)
            this.distance = 0;

        this.inertia.stop();
        this.isInMotion = true;
        this.lastPosition = position;
        this.raiseStart = true;
    };

    obj.motionContinued = function (position) {

        if (!this.isInMotion)
            return;

        if (this.raiseStart && position != this.lastPosition)
        {
            $(this).trigger('start');
            this.raiseStart = false;
        }

        this.inertia.registerMovement(position - this.lastPosition);
        this.motionContinuedInternal(position);
    };

    obj.motionContinuedInternal = function (position) {

        this.distance += position - this.lastPosition;
        this.lastPosition = position;
        var consumingData = this.motionConsumer(this.distance);
        this.distance -= consumingData.distance;

        if (consumingData.highFrictionRange && this.inertia.isInProgress) {
            this.inertia.movedIntoHighFrictionRange();
        }
    };

    obj.motionEnded = function (useInertia) {

        if (this.inertia.isInProgress)
            return;

        this.isInMotion = false;

        if (!useInertia) {
            $(this).trigger('end', this.distance);
            this.distance = 0;
        } else {
            this.inertia.run(this.lastPosition);
        }
    };

    obj.inertiaStop = function () {
        this.isInMotion = false;
    };
}
module.exports = function(carousel, settings) {

    var path_base = require('./path_base.js');
    var point = require('./../point.js');
    var getBezierBox = require('./../bezier_box.js');
    var getBezier = require('./../bezier.js');

    var base = new path_base(carousel, {
        xyPathBezierPoints: { p1: { x: -100, y: 0 }, p2: { x: 0, y: 0 }, p3: { x: 0, y: 0 }, p4: { x: 100, y: 0 } },
        xzPathBezierPoints: { p1: { x: -100, y: 0 }, p2: { x: 0, y: 0 }, p3: { x: 0, y: 0 }, p4: { x: 100, y: 0 } },
        xyArcLengthBezierPoints: { p1: { x: 0, y: 0 }, p2: { x: 50, y: 50 }, p3: { x: 50, y: 50 }, p4: { x: 100, y: 100 } },
        pathLength: 1000,
        zeroPosition: 0.5,
        width: 1000,
        height: 1000,
        depth: 1000
    });

    $.extend(this, base);
    $.extend(this.settings, settings);

    this.getPointInternal = function (value) {
        var distance = Math.abs(value - this.minValue());
        var absDist = Math.abs(this.maxValue() - this.minValue());

        var bezierT = Math.min(distance / absDist, 1);

        var xyPoints = this.settings.xyPathBezierPoints;
        var xyArcLengthPoints = this.settings.xyArcLengthBezierPoints;
        var xzPoints = this.settings.xzPathBezierPoints;

        var boxXY = getBezierBox(xyPoints.p1, xyPoints.p2, xyPoints.p3, xyPoints.p4);
        var boxXZ = getBezierBox(xzPoints.p1, xzPoints.p2, xzPoints.p3, xzPoints.p4);

        bezierT = getBezier(bezierT, xyArcLengthPoints.p1, xyArcLengthPoints.p2, xyArcLengthPoints.p3, xyArcLengthPoints.p4).y / 100;

        var pointXY = getBezier(bezierT, xyPoints.p1, xyPoints.p2, xyPoints.p3, xyPoints.p4);
        var x = this.normalizeVal(pointXY.x, boxXY.minX, boxXY.maxX);
        var y = this.normalizeVal(pointXY.y, boxXY.minY, boxXY.maxY);
        var z = getBezier(bezierT, xzPoints.p1, xzPoints.p2, xzPoints.p3, xzPoints.p4).y;
        z = this.normalizeVal(z, boxXZ.minY, boxXZ.maxY);

        x = this.settings.width * x - this.settings.width / 2;
        y = this.settings.height * y * -1;
        z = this.settings.depth * z * -1;

        return new point(x, y, z);
    };

    this.normalizeVal = function (val, a, b) {
        var dist = Math.abs(b - a);
        var min = Math.min(a, b);

        if (dist != 0)
            return (val - min) / dist;
        return val - min;
    };

    this.minValue = function () {
        return 0;
    };

    this.maxValue = function () {
        return this.settings.pathLength;
    };

    this.rootValue = function () {
        return this.settings.pathLength * this.settings.zeroPosition;
    };
};
module.exports = function(carousel) {

    extend(this);

    this.carousel = carousel;
    this.interval = setInterval($.proxy(this.update, this), 200);

    this.update();

    this.applayedWidth = null;
    this.applayedHeight = null;
    this.applayedScale = null;
};

function extend(obj) {
    obj.update = function () {

        var widthToApply = this.carousel.widget().width();
        var heightToApply = this.carousel.widget().height();
        var scaleToApply = null;

        if (widthToApply == 0 || heightToApply == 0)
            return;

        if (this.carousel.options.designedForWidth != null && this.carousel.options.designedForHeight != null) {
            scaleToApply = Math.min(widthToApply / this.carousel.options.designedForWidth, heightToApply / this.carousel.options.designedForHeight);
        }

        if (widthToApply != this.applayedWidth || heightToApply != this.applayedHeight || scaleToApply != this.applayedScale) {
            var container = $('.theta-carousel-inner-container', this.carousel.widget());

            if (scaleToApply != null) {
                widthToApply = widthToApply / scaleToApply;
                heightToApply = heightToApply / scaleToApply;

                // keep proportion
                if (widthToApply / heightToApply < this.carousel.options.designedForWidth / this.carousel.options.designedForHeight) {
                    heightToApply = widthToApply * (this.carousel.options.designedForHeight / this.carousel.options.designedForWidth);
                } else {
                    widthToApply = heightToApply * (this.carousel.options.designedForWidth / this.carousel.options.designedForHeight);
                }

                // reposition
                container.css({
                    left: this.carousel.widget().width() / 2 - widthToApply / 2,
                    top: this.carousel.widget().height() / 2 - heightToApply / 2,
                });

            }

            // applaying
            if (widthToApply != this.applayedWidth) {
                container.width(widthToApply);
                this.carousel.invalidate();
                this.applayedWidth = widthToApply;
            }

            if (heightToApply != this.applayedHeight) {
                container.height(heightToApply);
                this.carousel.invalidate();
                this.applayedHeight = heightToApply;
            }

            if (scaleToApply != this.applayedScale) {
                if (scaleToApply == null) {
                    container.css({
                        transform: 'translate3d(0px,0px, 100000px)',
                        position: 'static'
                    });
                } else {
                    container.css({
                        transform: 'translate3d(0px,0px, 100000px) scale(' + scaleToApply + ')',
                        position: 'relative'
                    });
                }
                this.carousel.invalidate();

                this.applayedScale = scaleToApply;
            }
        }
    };

    obj.getApplayedScale = function () {
        if (this.applayedScale)
            return this.applayedScale;
        return 1;
    };

    obj.destroy = function () {
        clearInterval(this.interval);
    };
}
module.exports = function (carousel, settings) {

    var effect_base = require('./effect_base.js');
    var getBezier = require('./../bezier.js');
    var vector = require('./../vector.js');
    var rotation = require('./../rotation.js');

    var base = new effect_base(carousel, {

    });

    $.extend(this, base);
    $.extend(this.settings, settings);

    this.apply = function (elementTransform, element, value) {
        var root = this.carousel.path.rootValue();
        var distance = Math.abs(value - root);
        var absDist = this.carousel.options.distance * this.carousel.options.rotationNumberOfConfigurableElements;

        var bezierT = 1 - Math.min(distance / absDist, 1);

        var points = this.carousel.options.rotationBezierPoints;
        var angle = getBezier(bezierT, points.p1, points.p2, points.p3, points.p4).y;

        if (value < root && this.carousel.options.rotationInvertForNegative)
            angle *= -1;

        var rotationVector = new vector();
        rotationVector.x = this.carousel.options.rotationVectorX,
        rotationVector.y = this.carousel.options.rotationVectorY,
        rotationVector.z = this.carousel.options.rotationVectorZ,
        elementTransform.rotations.push(new rotation(rotationVector, angle));
    };
};